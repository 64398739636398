import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IPageMeta } from '@ztarmobile/zwp-service';
import {
  ACCOUNT_ROUTE_URLS, LOGIN_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS,
  SUPPORT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, MIGRATION_ROUTE_URLS
} from './app.routes.names';

const routes: Routes = [
  // Paths
  { path: ROUTE_URLS.HOME, loadChildren: () => import('./core/home/home.module').then(m => m.HomeModule) },
  { path: ROUTE_URLS.BRING_PHONE, loadChildren: () => import('./core/bring-phone/bring-phone.module').then(m => m.BringPhoneModule) },
  { path: ROUTE_URLS.HD_VOICE, loadChildren: () => import('./core/hd-voice/hd-voice.module').then(m => m.HdVoiceModule) },
  { path: ROUTE_URLS.WIFI_CALLING, loadChildren: () => import('./core/wifi-calling/wifi-calling.module').then(m => m.WifiCallingModule) },
  { path: ROUTE_URLS.REFER_FRIEND, loadChildren: () => import('./core/refer-friend/refer-friend.module').then(m => m.ReferFriendModule) },
  { path: LOGIN_ROUTE_URLS.BASE, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: ACCOUNT_ROUTE_URLS.NAME, loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.LOGIN}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LOGIN}`, pathMatch: 'full',
    data: { title: 'Login', description: 'Login in to your Qué tal Mobile account' } as IPageMeta
  },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.SIGNUP}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.SIGN_UP}`, pathMatch: 'full',
    data: { title: 'Signup', description: 'Create your Qué tal Mobile account' } as IPageMeta
  },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.FORGET_PASSWORD}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.FORGOT_PASSWORD}`,
    data: { title: 'Forgot Password', description: 'Reset your Qué tal Mobile account password' } as IPageMeta
  },
  { path: ACTIVATION_ROUTE_URLS.NAME, loadChildren: () => import('./activation/activation.module').then(m => m.ActivationModule) },
  { path: SHOP_ROUTE_URLS.NAME, loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: SUPPORT_ROUTE_URLS.NAME, loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: MIGRATION_ROUTE_URLS.NAME, loadChildren: () => import('./migration/migration.module').then(m => m.MigrationModule) },
  // Redirects
  { path: ROUTE_URLS.DATA_SETUP, redirectTo: `${SUPPORT_ROUTE_URLS.NAME}/${SUPPORT_ROUTE_URLS.DATA_SETUP}`, pathMatch: 'full' },
  { path: ROUTE_URLS.CHECK_PHONE, redirectTo: `${ACTIVATION_ROUTE_URLS.NAME}/${ACTIVATION_ROUTE_URLS.CHECK_PHONE}`, pathMatch: 'full' },
  {
    path: ROUTE_URLS.PLANS, redirectTo: `${SHOP_ROUTE_URLS.NAME}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}`, data: {
      title: `Unlimited Talk & Text 4G LTE Cell Phone Plans`, description:
        // eslint-disable-next-line max-len
        `The best cell phone deal just got better! Get all the power of the nation’s largest networks for less.`
    } as IPageMeta
  },
  { path: ROUTE_URLS.LOGIN, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LOGIN}`, pathMatch: 'full' },
  { path: '', loadChildren: () => import('./core/home/home.module').then(m => m.HomeModule) },
  { path: '**', loadChildren: () => import('./core/not-found/not-found.module').then(m => m.NotFoundModule) },
];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {});

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
