<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <img ngSrc="assets/img/security.svg" alt="security-icon" width="24" height="24" loading="lazy">
        <div class="modal-header">
            <h2 class="modal-heading">{{context.title}}</h2>
        </div>
        <div class="modal-body">
                <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
                <div class="note" *ngIf="!!context.timer">
                    <p class="details">{{minutes}} : {{seconds}} <span translate>alert-security-modal.minutes-remaining</span></p>
                </div>
                <div class="modal-actions">
                    <div class="left" *ngIf="!!context.primaryBtn">
                        <button class="button primary"  (click)="OK()" id="action-button"
                            data-cy="action-button">{{context.primaryBtn}}</button>
                    </div>
                    <div class="right" *ngIf="!!context.secondaryBtn">
                        <button class="button secondary" (click)="cancel()" id="action-button"
                            data-cy="action-button">{{context.secondaryBtn}}</button>
                    </div>
                </div>
        </div>
    </div>
</div>