<footer [class.special-paddings]="selectedLanguage === 'es'">
    <div class="all-links">
    <div class="main-links">
        <div class="shop-lang">
        <div class="links-group">
            <div class="title" (click)="toggleMenu('Shop')">
                <h5 translate>footer.shop</h5>
                <picture>
                    <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Shop'" width="24" height="24" alt="arrow up" loading="lazy">
                    <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Shop'"  width="24" height="24" alt="arrow down" loading="lazy">
                </picture>
            </div>
            <div class="links" [ngClass]="{'opened': openedMenu=='Shop'}">
                <a data-cy="cellPhonePlans"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                    class="button tertiary" translate>footer.cell-phone-plans</a>
                <a data-cy="cellPhones"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
                    class="button tertiary" translate>footer.cell-phones</a>
            </div>
        </div>
        <div class="links-group" *ngIf="innerWidth>1024">
            <div class="title" (click)="toggleMenu('language')">
                <h5 translate>footer.language</h5>
                <picture>
                    <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='language'"  width="24" height="24" alt="arrow up" loading="lazy">
                    <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='language'"  width="24" height="24" alt="arrow down" loading="lazy">
                </picture>
            </div>
            <div class="links lang-links">
                <div [class.selected-lang]="selectedLanguage === 'en'" class="lang-div">
                    <button class="button tertiary lang-link" (click)="translateLanguage('en')">
                        <img class="lang-logo" width="24" height="24" ngSrc="/assets/icon/english-flag.svg"alt="flag" alt="flag"  width="24" height="24" loading="lazy">EN</button>
                    </div>
                <div [class.selected-lang]="selectedLanguage === 'es'" class="lang-div">
                <button  class="button tertiary lang-link" (click)="translateLanguage('es')">  <img class="lang-logo" width="24" height="24" ngSrc="/assets/icon/espanol-flag.svg"alt="flag" alt="flag" loading="lazy">ES</button>
                </div>
            </div>
        </div>
       </div>
        <div class="links-group">
            <div class="title" (click)="toggleMenu('Account')">
                <h5 translate>footer.account</h5>
                <picture>
                    <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Account'"  width="24" height="24" loading="lazy" alt="arrow up">
                    <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Account'"  width="24" height="24" loading="lazy" alt="arrow down">
                </picture>
            </div>
            <div class="links" [ngClass]="{'opened': openedMenu=='Account'}">
                <a data-cy="accountSummary" class="button tertiary"
                    [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ ACCOUNT_ROUTE_URLS.SUMMARY]" translate>footer.account-summary</a>

                <a data-cy="payAndRefill" class="button tertiary"
                    [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]" translate>footer.pay-and-refill</a>
                <a data-cy="yourOrders" class="button tertiary"
                    [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.ORDERS]" translate>footer.your-orders</a>
                <a data-cy="paymentrHistory" class="button tertiary"
                    [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.PAYMENTS]" translate>footer.payment-history</a>

                <a data-cy="usage" class="button tertiary"
                    [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.USAGE]" translate>footer.usage</a>

                <a data-cy="manageDevices" class="button tertiary"
                    [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]" translate>footer.manage-devices</a>
                <a data-cy="profileSettings" class="button tertiary"
                    [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.SETTINGS]" translate>footer.profile-settings</a>
            </div>
        </div>
        <div class="links-group">
            <div class="title" (click)="toggleMenu('Support')">
                <h5 translate>footer.support</h5>
                <picture>
                    <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Support'"  width="24" height="24" loading="lazy" alt="arrow up">
                    <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Support'"  width="24" height="24" loading="lazy" alt="arrow down">
                </picture>
            </div>
            <div class="links" [ngClass]="{'opened': openedMenu=='Support'}">
                <a data-cy="wifiCalling" class="button tertiary" [routerLink]="[ROUTE_URLS.WIFI_CALLING]" translate>footer.wifi-calling</a>
                <a data-cy="setupYourPhoneData" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.DATA_SETUP]" translate>footer.setup-phone-data</a>
                <a data-cy="hdVoice" class="button tertiary" [routerLink]="[ROUTE_URLS.HD_VOICE]" translate>footer.hd-voice</a>
                <a data-cy="faqs" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.FAQS]" translate>footer.faqs</a>
                <a data-cy="contactUs" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.CONTACT_US]" translate>footer.contact-us</a>
                <a data-cy="sitemapLink" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.SITEMAP]" translate>footer.sitemap</a>
                <a data-cy="sitemapLink" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.IOS17]" translate>footer.ios17</a>
            </div>
        </div>
        <div class="links-group">
            <div class="title" (click)="toggleMenu('About')">
                <h5 translate>footer.about</h5>
                <picture>
                    <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='About'"  width="24" height="24" loading="lazy" alt="arrow up">
                    <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='About'"  width="24" height="24" loading="lazy" alt="arrow down">
                </picture>
            </div>
            <div class="links" [ngClass]="{'opened': openedMenu=='About'}">
                <a data-cy="aboutG2g" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.ABOUT_QUETAL_MOBILE]" translate>footer.about-quetal</a>
                <a data-cy="whyG2g" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.WHY_QUETAL_MOBILE]" translate>footer.why-quetal</a>
                <a data-cy="howItWorks" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.HOW_IT_WORKS]" translate>footer.how-it-works</a>
                <a data-cy="intCalling" class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.INTERNATIONAL_CALLING]" translate>footer.intCalling</a>
            </div>
        </div>
        <div class="links-group info">
            <div class="links pl-0">
                <a class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS]" translate>footer.terms-and-conditions</a>
                <a class="button tertiary"
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.HEARING_AID_COMPATIBILITY]" translate>footer.hearing-aid-compatibility</a>
            </div>
        </div>
    </div>
    <div class="links-group" *ngIf="innerWidth<1025">
        <div class="title" (click)="toggleMenu('language')">
            <h5 translate>footer.language</h5>
            <picture>
                <img ngSrc="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='language'"  width="24" height="24" loading="lazy" alt="arrow up">
                <img ngSrc="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='language'"  width="24" height="24" loading="lazy" alt="arrow down">
            </picture>
        </div>
        <div class="links" [ngClass]="{'opened': openedMenu=='language'}">
            <div class="lang-first-div" [class.selected-lang-responsive]="selectedLanguage === 'en'" [class.left-margins]="selectedLanguage === 'es'">
                <button class="button tertiary no-top lang-link" (click)="translateLanguage('en')">
                    <img class="lang-logo" width="24" height="24" ngSrc="/assets/icon/english-flag.svg"alt="flag" alt="flag" loading="lazy">
                 English</button>
                  <img ngSrc="/assets/icon/primary-check.svg" alt="check" width="20" height="20" *ngIf="selectedLanguage === 'en'" loading="lazy">
              </div>
              <div [class.selected-lang-responsive]="selectedLanguage === 'es'">
                <button  class="button tertiary lang-link last" (click)="translateLanguage('es')">
                    <img class="lang-logo" width="24" height="24" ngSrc="/assets/icon/espanol-flag.svg"alt="flag" alt="flag" loading="lazy">
                   Español</button>      
                   <img ngSrc="/assets/icon/primary-check.svg" alt="check" width="20" height="20" *ngIf="selectedLanguage === 'es'" loading="lazy">            
              </div>
              
        </div>
    </div>
    </div>
    <div class="description-section">
        <div>
            <div class="bottom-text">
                <span translate>footer.copy-rights</span>
            </div>
            <div class="bottom-text">
                <span translate>footer.pixel-and-android</span>
            </div>
        </div>
        <div class="social-media-links">
            <a href="https://www.facebook.com/quetalmobile" target="_blank" rel="noopener">
                <picture>
                    <img ngSrc="assets/icon/facebook-black.svg" alt="Facebook Logo"  width="17" height="30" loading="lazy">
                </picture>
            </a>
            <a href="https://www.youtube.com/@quetalmobile" target="_blank" rel="noopener">
                <picture>
                    <img ngSrc="assets/icon/youtube-logo.svg" alt="youtube Logo"  width="22" height="16" loading="lazy">
                </picture>
            </a>
            <a href="https://www.instagram.com/quetalmobile" target="_blank" rel="noopener">
                <picture>
                    <img ngSrc="assets/icon/insta-black.svg" alt="Instagram Logo"  width="24" height="24" loading="lazy">
                </picture>
            </a>
            <a href="https://twitter.com/quetalmobile" target="_blank" rel="noopener">
                <picture>
                    <img ngSrc="assets/icon/x-black.svg" alt="X Logo"  width="24" height="24" loading="lazy">
                </picture>
            </a>
            <a href="https://wa.me/7208055084" target="_blank" rel="noopener">
                <picture>
                    <img ngSrc="assets/icon/whatsapp-black.svg" alt="WhatsApp Logo"  width="24" height="24" loading="lazy">
                </picture>
            </a>
          
        </div>
    </div>
    <div class="build">
        Build version: {{BUILD_VERSION}} - {{BUILD_DATE}} <span style="display: none"> -
            {{FIREBASE_CONF.projectId}}</span>
    </div>
    <ng-container *ngIf="loadCaptcha">

        <app-invisible-recaptcha (resolved)="resolvedCaptcha($event)" [siteKey]="SITE_ID" #invisibleReCaptcha>
        </app-invisible-recaptcha>
    </ng-container>
</footer>