import { Injectable } from '@angular/core';
import { IUserPlan, IFirebaseAddress, IFirebasePaymentMethod, CustomizableMobilePlan } from '@ztarmobile/zwp-service-backend';
import { IUser } from '@ztarmobile/zwp-services-auth';
import { IShipmentTracking } from '@ztarmobile/zwp-service-backend-v2';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Observable, Subject } from 'rxjs';
import { EmailVerificationModalComponent } from 'src/modals/email-verification-modal/email-verification-needed-modal.component';
import { AlertSecurityModalComponent } from 'src/modals/alert-security-modal/alert-security-modal.component';

export interface ModalSetting {
  title?: string;
  okText?: string;
  cancelText?: string;
  customClass?: string;
  enableHTML?: boolean;
}
export interface IModalHelper {
  showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string, accountSettingsRoute?: string): MatDialogRef<any>;
  showSpecificManagePaymentModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, paymentId?: string, customClass?: string): MatDialogRef<any>;
  showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string, customHTML?: string): MatDialogRef<any>;
  showConfirmMessage(message: string, settings?: ModalSetting, customHTML?: string): MatDialogRef<any>;
  showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any>;
  showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string, customHTML?: string,
    cancelBtn?: boolean, cancelText?: string, noteText?: string, specificCancelReturn?: string): MatDialogRef<any>;
  showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any>;
  showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any>;
  showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string,
    skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any>;
  showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any>;
  showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any>;
  showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    network?: string, labelText?: string, showRecaptcha?: boolean, iccid?: string): MatDialogRef<any>;
  showWifiCallingModal(title: string, termsRoute: string, customClass?: string, wifiAddress?: IFirebaseAddress): MatDialogRef<any>;
  showMigrationStepsModal(customClass?: string): MatDialogRef<any>;
  showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  successModal(message: string, buttonText?: string, customClass?: string, title?: string): MatDialogRef<any>;
  // tslint:disable-next-line:max-line-length
  showItemOutOFStockModal(title: string, customHTML: string, currentPlan: CustomizableMobilePlan, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  showTrackingModal(title: string, trackingDetails: IShipmentTracking, trackingNumber: string, customClass?: string): MatDialogRef<any>;
  showTMOSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any>;
  showeSIMModal(iccid: string, mdn: string, customClass?: string): MatDialogRef<any>;
  showeMigrationConfirmationModal(phone: string, imei: string, customClass?: string): MatDialogRef<any>;
  showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any>;
  showLoginPopup(): MatDialogRef<any>;
  showMigrationVerfiedFlow(title: string, customClass?: string, hasCloseLink?: boolean, selectedPlan?: IUserPlan, phoneImei?: string, planMdn?: string, selectedPlanId?: string, activationCode?: string): MatDialogRef<any>;
  showVerifyAddressModal(title: string, message: string, customHTML?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
}
@Injectable({
  providedIn: 'root'
})
export class ModalHelperService {
  constructor(private dialog: MatDialog) {
  }
  private createProxyDialogRef<T>(openDialog: () => Promise<MatDialogRef<T>>): MatDialogRef<T> {
    const afterClosedSubject = new Subject<any>();

    const proxyDialogRef: Partial<MatDialogRef<T>> = {
      afterClosed: () => afterClosedSubject.asObservable()
    } as Partial<MatDialogRef<T>>;

    const promise = openDialog().then(realDialogRef => {
      realDialogRef.afterClosed().subscribe(result => {
        afterClosedSubject.next(result);
        afterClosedSubject.complete();
      });

      Object.keys(realDialogRef).forEach(key => {
        if (typeof realDialogRef[key] === 'function') {
          proxyDialogRef[key] = (...args: any[]) => realDialogRef[key](...args);
        } else {
          Object.defineProperty(proxyDialogRef, key, {
            get: () => realDialogRef[key],
            set: value => realDialogRef[key] = value,
            enumerable: true,
            configurable: true
          });
        }
      });

      proxyDialogRef.close = (dialogResult?: T) => {
        afterClosedSubject.next(dialogResult);
        afterClosedSubject.complete();
        realDialogRef.close(dialogResult);
      };
    });

    return proxyDialogRef as MatDialogRef<T>;
  }

  private async loadAndOpenModal<T>(component: () => Promise<{ default: ComponentType<T> }>, data: any): Promise<MatDialogRef<T>> {
    const { default: componentType } = await component();
    return this.dialog.open(componentType, { data });
  }

  showConfirmMessage(message: string, settings?: ModalSetting, customHTML?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getConfirmMessageModalComponent() })),
      { message, settings, customHTML }
    );

    return this.createProxyDialogRef(openDialog);
  }
  showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string,
    accountSettingsRoute?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getManageAddressModalComponent() })),
      {
        isManage,
        userPlan,
        user,
        addressId,
        customClass,
        accountSettingsRoute
      });
    return this.createProxyDialogRef(openDialog);
  }
  showSpecificManagePaymentModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, paymentId?: string, customClass?: string,
    accountSettingsRoute?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getManagePaymentSpecificModalComponent() })),
      {
        isManage,
        paymentMethodId: paymentId,
        userPlan,
        user,
        customClass,
        accountSettingsRoute
      });
    return this.createProxyDialogRef(openDialog);
  }
  showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string, customHTML?: string): MatDialogRef<any> {
    console.warn('You are using showConfirmMessageModal, please use showConfirmMessage instead');
    return this.showConfirmMessage(message, { title, okText, cancelText, customClass, enableHTML: false }, customHTML);
  }
  showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getConfirmPasswordModalComponent() })),
      {
        message, title, customClass
      });
    return this.createProxyDialogRef(openDialog);
  }
  showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string,
    customHTML?: string, cancelBtn?: boolean, cancelText?: string, noteText?: string, specificCancelReturn?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getInformationMessageModalComponent() })), {
      title, message, btnText, btnUrl, hasCloseLink, customClass,
      customHTML, cancelBtn, cancelText, noteText, specificCancelReturn
    });
    return this.createProxyDialogRef(openDialog);
  }
  showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getFiveGModalComponent() })), {
      title, customHTML, linkText, linkRoute, customClass, hasCloseLink
    });
    return this.createProxyDialogRef(openDialog);
  }
  successModal(message: string, buttonText?: string, customClass?: string, title?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getSuccessModalComponent() })), {
      message, buttonText, customClass, title
    });
    return this.createProxyDialogRef(openDialog);
  }
  // tslint:disable-next-line:max-line-length
  showItemOutOFStockModal(title: string, customHTML: string, currentPlan: CustomizableMobilePlan, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getOutOfStockItemModalComponent() })), {
      title, customHTML, currentPlan, customClass, hasCloseLink
    });
    return this.createProxyDialogRef(openDialog);
  }
  showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getAddActivatedNumberModalComponent() })), {
      customClass, title, label
    });
    return this.createProxyDialogRef(openDialog);
  }
  showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getInputModalComponent() })), {
      title, message, okText, okBtnClass, customClass,
      isCancelable, cancelText, cancelBtnClass, labelText
    })
    return this.createProxyDialogRef(openDialog);
  }
  showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string,
    skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getRoutingModalComponent() })), {
      title, message, hasCloseLink, yesButtonText, noButtonText, skipButtonText, yesButtonLink, noButtonLink, skipButtonLink, customClass
    })
    return this.createProxyDialogRef(openDialog);
  }
  showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getEditCcModalComponent() })), {
      paymentMethod, title, note, noteLinkText, customClass
    });
    return this.createProxyDialogRef(openDialog);
  }
  showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getShippingAddressModalComponent() })), {
      title, shippingAddress, customClass
    });
    return this.createProxyDialogRef(openDialog);
  }
  showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    network?: string, labelText?: string, showRecaptcha?: boolean, iccid?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getSimReplacementModalComponent() })), {
      title, message, okText, okBtnClass, customClass, network, labelText, showRecaptcha, iccid
    });
    return this.createProxyDialogRef(openDialog);
  }
  showeSIMModal(iccid: string, mdn: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getEsimReplacementModalComponent() })), {
      iccid, mdn, customClass
    });
    return this.createProxyDialogRef(openDialog);
  }
  showWifiCallingModal(title: string, termsRoute: string, customClass?: string, wifiAddress?: IFirebaseAddress): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getWifiCallingModalComponent() })), {
      title, termsRoute, customClass, wifiAddress
    });
    return this.createProxyDialogRef(openDialog);
  }
  showMigrationStepsModal(customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getMigrationStepsComponent() })), {
      customClass
    });
    return this.createProxyDialogRef(openDialog);
  }
  showTrackingModal(title: string, trackingDetails: IShipmentTracking, trackingNumber: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getTrackingModalComponent() })), {
      title, trackingDetails, trackingNumber, customClass
    });
    return this.createProxyDialogRef(openDialog);
  }
  /* not used at the moment, but can be used when eSIM enabled */
  showTMOSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getCompatibilitySkipModalComponent() })), {
      title, hasCloseLink, customClass, notBeforeSkipping
    });
    return this.createProxyDialogRef(openDialog);
  }
  showeMigrationConfirmationModal(phone: string, imei: string, customClass?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getMigrationConfirmationModalComponent() })), {
      phone, imei, customClass
    });
    return this.createProxyDialogRef(openDialog);
  }

  showLoginPopup(): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getCheckoutLoginComponent() })), {});
    return this.createProxyDialogRef(openDialog);
  }
  showMigrationVerfiedFlow(title: string, customClass?: string, hasCloseLink?: boolean, selectedPlan?: IUserPlan, phoneImei?: string, planMdn?: string, selectedPlanId?: string, activationCode?: string): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getMigrationVerifiedFlowComponent() })), {
      title, customClass, hasCloseLink, selectedPlan, phoneImei, planMdn, selectedPlanId, activationCode
    });
    return this.createProxyDialogRef(openDialog);
  }
  showVerifyAddressModal(title: string, message: string, customHTML?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getVerifyAddressModalComponent() })), {
      title, message, customHTML, customClass, hasCloseLink
    });
    return this.createProxyDialogRef(openDialog);
  }
  showIphone4GInstructionsModal() {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getInstractionsFourGModalComponent() })), {
      width: '600px',
      maxWidth: '97%'
    });
    return this.createProxyDialogRef(openDialog);
  }
  showIphone5GInstructionsModal() {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getInstractionsFiveGModalComponent() })), {
      width: '600px',
      maxWidth: '97%'
    });
    return this.createProxyDialogRef(openDialog);
  }
  showIphone5GIos17InstructionsModal() {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getInstractionsFiveGIos17ModalComponent() })), {
      width: '600px',
      maxWidth: '97%'
    });
    return this.createProxyDialogRef(openDialog);
  }
  showGoogleLoginsModal() {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getGoogleLoginComponent() })), {})
    return this.createProxyDialogRef(openDialog);
  }
  showRecaptchaVerificationModal() {
    const openDialog = () => this.loadAndOpenModal(
      async () => await import('src/modals/modals.module').then(m => ({ default: m.ModalsModule.getRecaptchaVerificationComponent() })), {})
    return this.createProxyDialogRef(openDialog);
  }
  showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any> {
    return this.dialog.open(AlertSecurityModalComponent, {
      disableClose: true,
      data: {
        title, primaryBtn, secondaryBtn, timer, customClass, customHTML
      }
    });
  }
  showEmailVerificationNeededModal(email) : MatDialogRef<any> {
    return this.dialog.open(EmailVerificationModalComponent, {
      disableClose: true,
      data: {
        email
      }
    });
  }
}


