import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelperService } from '@services/toast-helper.service';
import { UserProfileService } from '@ztarmobile/zwp-service-backend-v2';

export class emailVerificationModalContext {
 email?: string
}

@Component({
  selector: 'app-email-verification-needed-modal',
  styleUrl: './email-verification-needed-modal.component.scss',
  templateUrl: './email-verification-needed-modal.component.html'
})
export class EmailVerificationModalComponent{
  public context: any;
  public email;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<emailVerificationModalContext>, private profileService: UserProfileService, private appState: AppState, private toastHelper: ToastrHelperService,
   private translateService: TranslateService) {
      this.context = data;
      this.email = this.context.email;
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
      return this.beforeClose();
  }
  closeDialog(result?): void {
    this.beforeDismiss();
    this.dialog.close(result);
  }
  public sendCode(): void {
    this.appState.loading = true;
    this.profileService.sendVerificationEmail(this.email).then(() => {
        this.appState.loading = false;
        this.toastHelper.showSuccess(this.translateService.instant('verify-email.new-verification-code-sent'));
        this.closeDialog('sent')
    }, (error) => {
        this.appState.loading = false;
        this.closeDialog('error')
    })
  }

}

