<div class="modal">
    <div class="container model-container email-verification">
        <div class="modal-header">
            <h4 class="header" translate>verify-email.email-verification-needed</h4>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                    <p class="noticed-note" translate>
                        verify-email.we-noticed-that
                    </p>
                <div class="actions">
                    <button class="button primary" (click)="sendCode()"
                        translate>verify-email.send-verification-link</button>
                </div>
            </div>
        </div>
    </div>
</div>