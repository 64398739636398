import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { IMarketingDetails } from '@ztarmobile/zwp-service-backend';
import { ContentfulService } from 'src/services/contentful.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthHttp } from '@ztarmobile/zwp-services-auth';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable({providedIn: 'root'})
export class AppState {
  public state: InternalStateType = {};
  public loading = false;
  public isCampaign = false;
  public userLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);
  public showPromoForAperiodOfTime: ReplaySubject<boolean> = new ReplaySubject(1);
  public showPromoForAperiodOfTimeObs: Observable<boolean>;
  public isMarketingCampaign: Observable<boolean>;
  public isMarktingCampaignReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public campaignQueryParams: Observable<any>;
  public campaignQueryParamsReplySubject: ReplaySubject<any> = new ReplaySubject(1);
  public utmsCampaign: Observable<boolean>;
  public utmsCampaignReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public utmsCampaignParams: Observable<any>;
  public utmsCampaignParamsReplySubject: ReplaySubject<any> = new ReplaySubject(1);
  public isMoneySavingProCampaign: Observable<boolean>;
  public isMoneySavingProReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public globalAlertHeightReplySubject: ReplaySubject<number> = new ReplaySubject(1);
  public globalAlertHeight: Observable<number>;
  public jsonLDString: any;
  public promoRemainingTime: ReplaySubject<{days: number, hours: number, minutes: number, seconds: number}> = new ReplaySubject(1);
  public promoRemainingTimeObs: Observable<{days: number, hours: number, minutes: number, seconds: number}>;
  public resolvedCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedSecondCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedCaptcha: Observable<string>;
  public resolvedSecondCaptcha: Observable<string>;
  public resetAndExecuteCaptchaSubject: ReplaySubject<{action:string}> = new ReplaySubject(1);
  public resetAndExecuteCaptcha: Observable<{action:string}>;
  public resetAndExecuteSecondCaptchaSubject: ReplaySubject<{action:string}> = new ReplaySubject(1);
  public resetAndExecuteSecondCaptcha: Observable<{action:string}>;

  private jsonLd: any = {};

  constructor(private sanitizer: DomSanitizer, private contentfulService: ContentfulService, private translate: TranslateService,
    private authHttp: AuthHttp) {
    this.isMarketingCampaign = this.isMarktingCampaignReplySubject.asObservable();
    this.campaignQueryParams = this.campaignQueryParamsReplySubject.asObservable();
    this.isMoneySavingProCampaign = this.isMoneySavingProReplySubject.asObservable();
    this.globalAlertHeight = this.globalAlertHeightReplySubject.asObservable();
    this.utmsCampaign = this.utmsCampaignReplySubject.asObservable();
    this.utmsCampaignParams = this.utmsCampaignParamsReplySubject.asObservable();
    this.showPromoForAperiodOfTimeObs = this.showPromoForAperiodOfTime.asObservable();
    this.promoRemainingTimeObs = this.promoRemainingTime.asObservable();
    this.resolvedCaptcha = this.resolvedCaptchaSubject.asObservable();
    this.resolvedSecondCaptcha = this.resolvedSecondCaptchaSubject.asObservable();
    this.resetAndExecuteCaptcha = this.resetAndExecuteCaptchaSubject.asObservable();
    this.resetAndExecuteSecondCaptcha = this.resetAndExecuteSecondCaptchaSubject.asObservable();
  }

  public clearSessionStorage(): void {
    sessionStorage.removeItem('shippingAddress');
    sessionStorage.removeItem('shippingMethod');
    sessionStorage.removeItem('plan_id');
    sessionStorage.removeItem('payment_id');
    sessionStorage.removeItem('auto_renew');
    sessionStorage.removeItem('purchased');
    sessionStorage.removeItem('activation');
    sessionStorage.removeItem('device');
    sessionStorage.removeItem('emailMdn');
    sessionStorage.removeItem('MoneyProReferral');
    sessionStorage.removeItem('tracked');
    sessionStorage.removeItem('anonymous');
    sessionStorage.removeItem('pendingAddress');
    sessionStorage.removeItem('changeNextCycle');
    sessionStorage.removeItem('skippedPlans');
    sessionStorage.removeItem('termsAccepted');
    sessionStorage.removeItem('referralCode');
    sessionStorage.removeItem('useFromBalance');
    sessionStorage.removeItem('useFromReward');
    sessionStorage.removeItem('removeFromCart');
    sessionStorage.removeItem('editPayment');
    sessionStorage.removeItem('isMigrationSimRemoved');
    sessionStorage.removeItem('selectedPlan');
    sessionStorage.removeItem('checkedDevice');
    sessionStorage.removeItem('phone');
    sessionStorage.removeItem('navigatedToPayment');
  }

  public setJsonLdData(data): any {
    this.jsonLd = data;
    this.jsonLd = Object.assign({'@context': 'http://schema.org/'}, this.jsonLd);
    this.jsonLDString = '<script type="application/ld+json">' + JSON.stringify(this.jsonLd) + '</script>';
    this.jsonLDString = this.sanitizer.bypassSecurityTrustHtml(this.jsonLDString);
    return this.jsonLDString;
  }

  public removeEmptyValues(obj): any {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmptyValues(obj[key]);
      } else if (obj[key] == null || obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }

  public useLanguage(language: string): void {
    this.translate.use(language);
    if (language === 'es') {
      this.authHttp.setLanguage('es');
    } else {
      this.authHttp.setLanguage(undefined);
    }
  }
  public promoTimeRemaining(): void {
    this.contentfulService.getContent('prmotionConfigs').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        const countdownDate = new Date(result?.promoEndDate);
        setInterval(() => {
          const now = new Date();
          const distance = countdownDate.getTime() - now.getTime();
    
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          
          this.promoRemainingTime.next({days, hours, minutes, seconds});
        }, 1000);
      }
    });
  }
  public validatePromo(): void {
    this.contentfulService.getContent('prmotionConfigs').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        const currentDate = new Date();
        // this is in pacific time
        const pacificCurrentDate = currentDate.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
        });
        const promoEndDate = new Date(result?.promoEndDate);
        const pacificPromoEndDate = promoEndDate.toLocaleString("en-US", {
          timeZone: "America/Los_Angeles"
        });
        if(new Date(pacificCurrentDate) <= new Date(pacificPromoEndDate)) {
          this.showPromoForAperiodOfTime.next(true);
         } else {
          this.showPromoForAperiodOfTime.next(false);
         } 
      }
    });
  }
  public setMarketingObject(utms): IMarketingDetails {
    const marketingObject: IMarketingDetails = {
      timestamp: new Date().toISOString(), utm_campaign: utms.utm_campaign,
      utm_medium: utms.utm_medium, utm_source: utms.utm_source, attributes: [], url: utms.url, utm_content: !!utms.utm_content ? utms.utm_content : null,
      utm_term: !!utms.utm_term ? utms.utm_term : null
    };
    if (!!utms.agentID || !!utms.agentid) {
      marketingObject.attributes.push({ name: 'agentID', value: !!utms.agentID ? utms.agentID : utms.agentid });
    }
    marketingObject.attributes.push({ name: 'vendorID', value: !!utms && !!utms.vendorID ? utms.vendorID : 'quetal' });
    return marketingObject;
  }
  public checkActivePromo(): boolean {
    const maxDate = new Date('July 05, 2023 14:59:00 GMT-06:00');
    const todayDate = new Date();
    return todayDate <= maxDate;
  }
}
