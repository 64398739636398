import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SUPPORT_ROUTE_URLS, SHOP_ROUTE_URLS, ACCOUNT_ROUTE_URLS, ROUTE_URLS, PHONES_SHOP_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS } from '../../app/app.routes.names';
import { BUILD_VERSION, ENV_FIREBASE_CONFIG, INVISIBLE_CAPTCHA_ID } from 'src/environments/environment';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { AppState } from 'src/app/app.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { InvisibleRecaptchaComponent } from '@widgets/invisible-recaptcha/invisible-recaptcha.component';

@Component({
  selector: 'app-footer-main-navbar',
  templateUrl: 'footer-main-navbar.component.html',
  styleUrls: ['./footer-main-navbar.component.scss']
})
export class FooterMainNavbarComponent implements OnInit {
  @ViewChild('invisibleReCaptcha') invisibleReCaptcha: InvisibleRecaptchaComponent;
  public SITE_ID = INVISIBLE_CAPTCHA_ID;
  public ROUTE_URLS = ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public PHONES_SHOP_ROUTE_URLS = PHONES_SHOP_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public BUILD_VERSION = BUILD_VERSION;
  public BUILD_DATE = new Date();
  public FIREBASE_CONF = ENV_FIREBASE_CONFIG;
  public isLoggedIn = false;
  public selectedLanguage = 'es';
  public lang = 'es';
  public innerWidth;
  public captchaResponse: string;
  public captchaValid = false;
  public isSeconCaptcha = false;
  public loadCaptcha = false;

  openedMenu: string;

  constructor(private simpleAuthService: SimpleAuthService,
    private appState: AppState, private translate: TranslateService) { 
      const savedLang = sessionStorage.getItem('lang');
      if (!!savedLang) {
        this.selectedLanguage = savedLang;
        this.appState.useLanguage(this.selectedLanguage);
      } else {
        this.selectedLanguage = 'es';
        sessionStorage.setItem('lang', this.selectedLanguage);
      }
      this.innerWidth = window.innerWidth;
      setTimeout(() => {
        console.info('load recaptcha')
        this.loadCaptcha = true;
      }, 6000);
    }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
    this.simpleAuthService.userState.subscribe((authState) => {
      this.isLoggedIn = !!authState && !authState.isAnonymous;
    });
    this.appState.resetAndExecuteCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = false;
      }
    });
    this.appState.resetAndExecuteSecondCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = true;
      }
    });
  }

  toggleMenu(name: string) {
    this.openedMenu = this.openedMenu == name ? null : name;
  }
  public resolvedCaptcha(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    this.captchaValid = !!captchaResponse;
    if(!this.isSeconCaptcha) {
      this.appState.resolvedCaptchaSubject.next(captchaResponse);
    } else {
      this.appState.resolvedSecondCaptchaSubject.next(captchaResponse);
    }
  }
  public translateLanguage(lang): void {
    this.selectedLanguage = lang === 'es' ? 'es' : 'en';
    sessionStorage.setItem('lang', this.selectedLanguage);
    this.appState.useLanguage(this.selectedLanguage);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
  }
}
