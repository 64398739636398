/* eslint-disable */
import { enableProdMode, NgModuleRef } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';
import { Environment } from './model';

export const ENV_FIREBASE_CONFIG = {
  apiKey: "AIzaSyC3APzhWNbWNPAroilwCIa_w_JCRIDEi8M",
  authDomain: "ztar-product-quetal-prod.firebaseapp.com",
  databaseURL: "https://ztar-product-quetal-prod-default-rtdb.firebaseio.com",
  projectId: "ztar-product-quetal-prod",
  storageBucket: "ztar-product-quetal-prod.appspot.com",
  messagingSenderId: "671391295326",
  appId: "1:671391295326:web:6315a15154d84ffe2bd1cf"
};
export const Title1 = 'stage';
export const Title: string = 'FREE Cell Phone Service via ACP | Qué tal Mobile';
export const Description = "Looking for a cheap cell phone plan? Choose Qué tal Mobile using the largest and fastest 5G network with no long-term commitment, no credit check. Bring your own phone";
export const ENDPOINT_URL = 'https://zwp-bff-staging-qbh6gbb5rq-uc.a.run.app';
export const GOOGLE_CLIENT_ID = '137465270934-7gipmpdjkmdlb22m665n151897su6ln9.apps.googleusercontent.com';
export const ACP_CALLBACK_URL = 'https://qtm-stage.ztarmobile.io';
export const WEBSITE_URL = 'https://qtm-stage.ztarmobile.io';
export const BFF_ENDPOINT_URL = 'https://zwp-bff-staging-qbh6gbb5rq-uc.a.run.app';
export const CUSTOMER_CARE_NUMBER: string = '8559907825';
export const CAPTCHA_SITE_ID = '6LeOIS0UAAAAACRW1uF2Vhq5zA9ciLvWKZrZXE9P';
export const INVISIBLE_CAPTCHA_ID = '6LebMcMpAAAAAH0JWFGp1ubZC8R_rEGuVoMVL6pz';
export const BUILD_VERSION = require('../../package.json').version;
export const BUILD_DATE = JSON.stringify((new Date()).toISOString());
export const GEOCODE_API_KEY = 'AIzaSyBLEp1LJrBqnCibrCdjkptaOB66y68B-aU';
export const GTM_API_KEY = 'GTM-5SHWNRGG';
export const GOOGLE_ADSENSE_CLIENT = 'ca-pub-6676283552039995';
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}$';
export const CONTENTFUL = {
  spaceId: '638kbvwns5uj',
  token: 'D1AYPEPgudvcVevLlqYKd9ppancaT8wxeVELqUNgegE',
  environment: 'production'
};
export const ENABLE_ESIM = true;
export const ZMP_G2G_BFF_ENDPOINT_URL = 'https://zmp-bff-staging-qbh6gbb5rq-uc.a.run.app';
export const environment: Environment = {
  production: false,
  showDevModule: true,

  /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
  decorateModuleRef(modRef: NgModuleRef<any>) {
    disableDebugTools();
    return modRef;
  },
  ENV_PROVIDERS: [

  ]
};