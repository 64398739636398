import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActionsAnalyticsService, CART_TYPES, CustomizableMobilePlan, INewSimConfiguration, ShippingConfigurationService } from '@ztarmobile/zwp-service-backend';
import * as CartUtil from '../app/shop/cart/cart-utils';
import { AnalyticsItem } from './analyticsItem';
import { take } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})

export class AnalyticsService {
  simConfig: INewSimConfiguration;

  constructor(@Inject(DOCUMENT) private dom, private actionsAnalyticsService: ActionsAnalyticsService, private shippingConfigurationService: ShippingConfigurationService) {
  }
  public trackAddToCart(items, type?: string): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'add_to_cart',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackRemoveFromCart(items, type?: string): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'remove_from_cart',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackViewCart(items): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'view_cart',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackBeginCheckout(items): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'begin_checkout',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackAddShippingInfo(items, shippingMethod): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'add_shipping_info',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            shipping_tier: shippingMethod,
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackAddPaymentInfo(items, paymentMethod): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'add_payment_info',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            payment_type: paymentMethod,
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackPurchaseEvent(items, shippingCost, taxes, transactionId, intent): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'purchase',
        ecommerce: {
            transaction_id: transactionId,
            currency: "USD",
            value: this.calculateTotal(items),
            tax: taxes || 0,
            shipping: shippingCost || 0,
            items: items,
            purchase_intent: intent
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackViewItem(items): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'view_item',
        ecommerce: {
            currency: "USD",
            value: this.calculateTotal(items),
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackSelectItem(id, name, items): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'select_item',
        ecommerce: {
            item_list_id: id,
            item_list_name: name,
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }

  public trackViewItemList(id, name, items): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'view_item_list',
        ecommerce: {
            item_list_id: id,
            item_list_name: name,
            items: items
        }
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }

  public trackLogin(id): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'login',
        ecommerce: {
            method: 'firebase',
        },
        user_id: id
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public trackSignup(id, lang:string): void {
    this.clearEcommerceDataLayer();
    const layerToPush = {
        event: 'sign_up',
        ecommerce: {
            method: 'firebase',
            preferred_language: lang,
        },
        user_id: id
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  public calculateTotal(items): number {
    if (!items) {
      return 0;
    }

    let total = 0;
    items.map((item) => {
        total += item.price * (!!item.quantity ? item.quantity : 1) - (!!item.discount ? item.discount : 0)
    })
    total = Math.max(total, 0);
    return total;
  }

  public calculatePlanDiscount(plan): number {
    if (!plan) {
      return 0;
    }

    let discount = 0;
    if (!!plan.specialPromotion && plan.specialPromotion?.promotionAmount) {
        discount += !!plan?.specialPromotion.promotionCycles ? plan.specialPromotion.promotionAmount * plan?.specialPromotion.promotionCycles : plan.specialPromotion.promotionAmount;
    }
    if (!!plan.promoPrice) {
        discount += plan.promoPrice;
    }
    if (!!plan.specialPromotion && !!plan.specialPromotion.promotionDiscount) {
        const discountAmountValue = plan.specialPromotion.promotionDiscount.split('%')[0];
        const discountValue = parseInt(discountAmountValue, 10);
        discount += plan.price * (discountValue / 100);
    }

    discount = Math.max(discount, 0);
    return discount;
  }
  
  public prepareItems(cartType: string, items): Array<AnalyticsItem> {
    const itemsArray = [] as Array<AnalyticsItem>;
    if (cartType === CART_TYPES.NEW_PLAN) {
        const planItem: AnalyticsItem = this.setPlanItem(items.plan);
        itemsArray.push(planItem);
        if (!!items.sim) {
            const simItem = this.setSIMItem(items.sim, true)
            itemsArray.push(simItem)
        }
        if (!!items.phone) {
            const phoneItem: AnalyticsItem = this.setPhoneItem(items.phone);
            itemsArray.push(phoneItem)
        }
    }
    if (cartType === CART_TYPES.GENERIC_CART) {
        const phoneItem: AnalyticsItem = this.setPhoneItem(items.phone);
        itemsArray.push(phoneItem)
        const simItem = this.setSIMItem(items.sim, true)
        itemsArray.push(simItem)
    }
    if (cartType === CART_TYPES.CHANGE_PLAN) {
        const planItem: AnalyticsItem = this.setPlanItem(items.plan);
        itemsArray.push(planItem);
    }
    if (cartType === CART_TYPES.TOPUP_PLAN) {
        const planItem: AnalyticsItem = this.setPlanItem(items.plan);
        itemsArray.push(planItem);
    }
    if (cartType === CART_TYPES.PLAN_ITEMS) {
        if (!!items.sim) {
            const simItem = this.setSIMItem(items.sim, false)
            itemsArray.push(simItem)
        }
        if (items.addons) {
            items.addons.map((addon) => {
                const addonItem = this.setAddonItem(addon)
                itemsArray.push(addonItem)
            });
        }
    }
    return itemsArray;
  }
  public setPlanItem(plan): AnalyticsItem {
      const planItem: AnalyticsItem = {
          item_id: plan?.id,
          item_name: plan?.title,
          affiliation: 'Quetal Mobile',
          discount: this.calculatePlanDiscount(plan),
          item_brand: 'Quetal',
          item_category: 'Plan',
          item_list_id: 'plans-list',
          item_list_name: 'Plans List',
          quantity: 1,
          price: this.calculatePlanPrice(plan)
        }
    return planItem;
  }
  public setSIMItem(sim, isFree?: boolean): AnalyticsItem {
   
    const simItem: AnalyticsItem = {
        item_id: sim?.skuNumber,
        item_name: 'SIM Card',
        affiliation: 'Quetal Mobile',
        discount: !isFree ?  0 : 5,
        item_brand: 'Quetal',
        item_category: 'SIM',
        quantity: 1,
        coupon: !!isFree? 'FREE_SIM' : null,
        price: 5
    }
    return simItem;
  }
  public setAddonItem(addon): AnalyticsItem {
    const addonItem: AnalyticsItem = {
        item_id: addon.id,
        item_name: addon.title,
        affiliation: 'Quetal Mobile',
        discount:  0,
        item_brand: 'Quetal',
        item_category: 'AddOn',
        item_list_id: 'addons-list',
        item_list_name: 'Addons List',
        quantity: addon.quantity,
        price: addon.price
    }
    return addonItem;
  }
  public setPhoneItem(phone): AnalyticsItem {
    const phoneItem: AnalyticsItem = {
        item_id: phone?.sku || phone?.fields?.phoneId,
        item_name: phone?.name || phone?.fields?.phoneName,
        affiliation: 'Quetal Mobile',
        item_brand: phone?.brand || '',
        item_category: phone?.type || 'Smartphone',
        quantity: 1,
        item_list_id: 'phones-list',
        item_list_name: 'Phones List',
        price: phone?.price || phone?.fields?.defaultPrice
    }
    return phoneItem
  }

  public clearEcommerceDataLayer() {
    const layerToPush = {
      ecommerce: null
    };
    this.actionsAnalyticsService.trackGAEvent(layerToPush);
  }
  private calculatePlanPrice(plan): number {
    let total = 0;
    const priceAmount = !!plan && !!plan?.specialPromotion && plan?.specialPromotion?.promotionCycles
        ? plan.specialPromotion?.promotionCycles * plan?.price
        : (!!plan?.subscriptionCycles ? plan?.subscriptionCycles * plan?.price : plan?.price);
        total += priceAmount;
    return total;
  }
}
